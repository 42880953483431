import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import { NgxRolesService } from 'ngx-permissions';
import { InternalStorage, LoopBackConfig } from './_sdk';
import { environment } from 'src/environments/environment';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'ds-smith';

  constructor(
    @Inject(InternalStorage) protected storage: InternalStorage,
    private rolesService: NgxRolesService,
    private ngSelectConfig: NgSelectConfig) {

    // Define Loopback config
    LoopBackConfig.setBaseURL(environment.apiBaseUrl);
    LoopBackConfig.setApiVersion(environment.apiVersion);

    // Set the locale
    registerLocaleData(localeNl, 'nl-NL');

    // Set locale for moment
    moment.locale('nl');

    // Load roles on reload
    const cached = this.storage.get('roles');
    const roles = typeof cached === 'string' ? JSON.parse(cached) : cached;

    if (roles) {
      this.rolesService.flushRoles();
      Object.keys(roles).map(role => this.rolesService.addRole(role, () => true));
    }

    // Set global configuration for ng-select
    this.ngSelectConfig.notFoundText = 'Niets gevonden';
    this.ngSelectConfig.loadingText = 'Laden...';
    this.ngSelectConfig.clearAllText = 'Maak leeg';
    this.ngSelectConfig.typeToSearchText = 'Type om te zoeken';
  }
}
