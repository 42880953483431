import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { InternalStorage, UserModelApi } from "../_sdk";
import { NotificationService } from "../_core";
import { Router } from "@angular/router";
import { NgxRolesService } from "ngx-permissions";
import { environment } from "src/environments/environment";
import { permissions } from "src/environments/permissions";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading: boolean;

  constructor(
    @Inject(InternalStorage) protected storage: InternalStorage,
    private _rolesService: NgxRolesService,
    private _formBuilder: FormBuilder,
    private _auth: UserModelApi,
    private _router: Router,
    private _notificationService: NotificationService
  ) {}

  ngOnInit() {
    // Initialize the login form
    this._initializeForm();
  }

  /**
   * On login
   */
  onLogin(): void {
    // Set loading
    this.isLoading = true;

    const loginData = this.loginForm.getRawValue();
    loginData.email = loginData.email.toLowerCase();

    // Call the login method and pass our form value
    this._auth.login(loginData, { user: "roles" }).subscribe(
      (token) => {
        // Load permissions of token
        const userRoles = Array.from(
          new Set(token.user.roles.map((r: any) => r.name))
        );
        this._rolesService.flushRoles();
        permissions.roles.map((r) => {
          if (userRoles.indexOf(r.value) !== -1) {
            this._rolesService.addRole(r.role, () => true);
          }
        });

        // Save permissions to local storage for reload
        this.storage.set(
          "roles",
          JSON.stringify(this._rolesService.getRoles()),
          new Date(new Date().getTime() + token.ttl * 1000)
        );

        // Set loading
        this.isLoading = false;

        if (this._rolesService.getRoles()["CHECKIN"]) {
          // Navigate to dashboard
          this._router.navigate(["admin/check-in"]);
        } else if (
          this._rolesService.getRoles()["GATEKEEPER"] ||
          this._rolesService.getRoles()["WKC"]
        ) {
          // Navigate to dashboard
          this._router.navigate(["admin/stops"]);
        } else {
          // Navigate to dashboard
          this._router.navigate(["admin/dashboard"]);
        }
      },
      (error) => {
        // Find out what is wrong and show the appropriate error message
        switch (error.code) {
          /**
           * Locked for certain period
           */
          case "ACCOUNT_LOCKED":
            // Show message
            this._notificationService.showErrorMessage(
              "Uw account is 15 minuten lang vergrendeld vanwege herhaalde mislukte aanmeldingspogingen. "
            );
            break;
          /**
           * Disabled by admin
           */
          case "ACCOUNT_DISABLED":
            // Show message
            this._notificationService.showErrorMessage(
              "Uw account is uitgeschakeld."
            );
            break;
          /**
           * First time login - requires change of password
           */
          case "PASSWORD_EXPIRED_FIRST_LOGIN":
            // Show message
            this._notificationService.showSuccessMessage(
              "Uw eerste loginpoging vereist een wachtwoordwijziging"
            );

            // Go to the change-password route
            this._router.navigate(["change-password"]);

            break;
          /**
           * Password expired - requires change of password
           */
          case "PASSWORD_EXPIRED":
            // Show message
            this._notificationService.showErrorMessage(
              "Uw wachtwoord is verlopen en moet worden gewijzigd."
            );

            // Go to the change-password route
            this._router.navigate(["change-password"]);

            break;
          /**
           * No permissions assigned
           */
          case "NO_PERMISSIONS":
            // Show message
            this._notificationService.showErrorMessage(
              "Uw account heeft nog geen toestemming."
            );
            break;
          /**
           * Any other error
           */
          default:
            // Show message
            this._notificationService.showErrorMessage(
              "Uw gebruikersnaam of wachtwoord is onjuist!"
            );
            break;
        }

        // Set loading
        this.isLoading = false;
      }
    );
  }

  _initializeForm(): void {
    this.loginForm = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      remember: [null, []],
    });
  }
}
