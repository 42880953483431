import { Component } from "@angular/core";
import { UserModelApi } from "src/app/_sdk";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
  loggedIn: Boolean = false;

  constructor(private _auth: UserModelApi) {}

  ngOnInit() {
    // Check the user is logged in already
    if (this._auth.isAuthenticated()) {
      this.loggedIn = true;
    }
  }
}
