//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { Injectable, Optional } from '@angular/core';


//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { Subject } from 'rxjs';


//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { NotificationStateInterface } from 'src/app/_shared/_interfaces';

/**
 * @file Notification which is a wrapper to show the existing notification modal
 * @author Rob van Dam
 * @copyright Dockbite 2017
 * @todo
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private successNotificationSubject = new Subject<NotificationStateInterface>();
  private errorNotificationSubject = new Subject<NotificationStateInterface>();

  successNotification$ = this.successNotificationSubject.asObservable();
  errorNotification$ = this.errorNotificationSubject.asObservable();

  constructor() { }

  showSuccessMessage(message: string, timeout?: number) {
    this.successNotificationSubject.next({ message, timeout });
  }

  showErrorMessage(message?: string, timeout?: number) {
    this.errorNotificationSubject.next({ message, timeout });
  }

  showDuplicateError(title: string, timeout?: number) {
    this.errorNotificationSubject.next({ message: `The ${title} already exists. Please choose a different name for your ${title}.`, timeout });
  }
}
