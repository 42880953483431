//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from "@angular/core";

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { NotificationService } from "../../../_core";
import { NotificationStateInterface } from "../../_interfaces";

import { faXmark } from "@fortawesome/pro-light-svg-icons";

/**
 * @file Generic notification modal used for showing success & error messages
 * @author Rob van Dam
 * @copyright Dockbite 2017
 * @todo
 */
@Component({
  selector: "app-notification-modal",
  templateUrl: "./notification-modal.component.html",
})
export class NotificationModalComponent implements OnInit, OnDestroy {
  /** ref to the modals template */
  @ViewChild("content", { static: true })
  private content: ElementRef;

  /*Close icon */
  faXmark = faXmark;

  /** will contain the subscribtion to the notification service */
  private successNotificationSub: Subscription;
  private errorNotificationSub: Subscription;

  /** the notification message we're going to display */
  message: string;

  /** the default timeout before closing the modal */
  timeout = 4000;

  /** the header message */
  headerMsg: string;

  /** inject our NgbModal service */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService
  ) {}

  /**
   *
   * @param {string} type - success or error
   * @param {string} message - the message to display
   * @param {number} timeout - time to wait before closing the modal in ms. Default 2500.
   */
  private showModal(type: string, message: string, timeout: number = 2500) {
    /** set the message */
    this.message = message;

    /** set the timeout */
    this.timeout = timeout;

    /** set headerMessage */
    if (type === "error") {
      this.headerMsg = "Er is iets fout gegaan!";
    } else {
      this.headerMsg = "Gelukt!";
    }

    /** show the modal with the correct class and save a ref */
    const modalRef = this.modalService.open(this.content, {
      backdrop: false,
      windowClass: `notification ${type}`,
    });

    /** close the modal after the set timeoutt */
    setTimeout(() => modalRef.close(), this.timeout);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * Shows the modal in its success state and close after the default or given timeout
   * @param {string} message - the message to display
   * @param {number} timeout - time to wait before closing the modal in ms. Default 2500.
   */
  showSuccessMessage(message: string, timeout?: number) {
    this.showModal("success", message, timeout);
  }

  /**
   * Shows the modal in its success state and close after the default or given timeout
   * @param {string} message - the message to display
   * @param {number} timeout - time to wait before closing the modal in ms. Default 2500.
   */
  showErrorMessage(
    message: string = "Oops! Something went wrong!",
    timeout?: number
  ) {
    this.showModal("error", message, timeout);
  }

  /**
   * Angular component lifetime hook where we set-up our mess
   * @return {void}
   */
  ngOnInit() {
    /** listen for changes on our service success observer and call the showSuccessMessage when we get a new event */
    this.successNotificationSub =
      this.notificationService.successNotification$.subscribe(
        (state: NotificationStateInterface) => {
          this.showSuccessMessage(state.message, state.timeout);
        }
      );

    /** listen for changes on our service error observer and call the showErrorMessage when we get a new event */
    this.errorNotificationSub =
      this.notificationService.errorNotification$.subscribe(
        (state: NotificationStateInterface) => {
          this.showErrorMessage(state.message, state.timeout);
        }
      );
  }

  /**
   * Angular component lifetime hook where we clean-up our mess
   * @return {void}
   */
  ngOnDestroy() {
    this.successNotificationSub.unsubscribe();
    this.errorNotificationSub.unsubscribe();
  }
}
