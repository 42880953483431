/* tslint:disable */
import {
  Hazard,
  ControlTemplate
} from '../index';

declare var Object: any;
export interface HazardTemplateInterface {
  "description": string;
  "hidden": boolean;
  "id"?: any;
  hazards?: Hazard[];
  controlTemplates?: ControlTemplate[];
}

export class HazardTemplate implements HazardTemplateInterface {
  "description": string;
  "hidden": boolean;
  "id": any;
  hazards: Hazard[];
  controlTemplates: ControlTemplate[];
  constructor(data?: HazardTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `HazardTemplate`.
   */
  public static getModelName() {
    return "HazardTemplate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of HazardTemplate for dynamic purposes.
  **/
  public static factory(data: HazardTemplateInterface): HazardTemplate{
    return new HazardTemplate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'HazardTemplate',
      plural: 'HazardTemplates',
      path: 'HazardTemplates',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "hidden": {
          name: 'hidden',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        hazards: {
          name: 'hazards',
          type: 'Hazard[]',
          model: 'Hazard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'hazardTemplateId'
        },
        controlTemplates: {
          name: 'controlTemplates',
          type: 'ControlTemplate[]',
          model: 'ControlTemplate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'hazardTemplateId'
        },
      }
    }
  }
}
