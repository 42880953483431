import { NgModule } from "@angular/core";
import { FeatherModule } from "angular-feather";
import {
  Camera,
  Heart,
  Search,
  Home,
  Calendar,
  Users,
  LogOut,
  Plus,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  ArrowLeft,
  ArrowRight,
  Save,
  Trash2,
  UserCheck,
  UserX,
  Filter,
  X,
  Minus,
} from "angular-feather/icons";

const icons = {
  Camera,
  Heart,
  Search,
  Home,
  Calendar,
  Users,
  LogOut,
  Plus,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  ArrowLeft,
  ArrowRight,
  Save,
  Trash2,
  UserCheck,
  UserX,
  Filter,
  X,
  Minus,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}

// NOTES:
// 1. We add FeatherModule to the 'exports', since the <i-feather> component will be used in templates of parent module
// 2. Don't forget to pick some icons using FeatherModule.pick({ ... })
