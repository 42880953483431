/* tslint:disable */
import {
  Visit
} from '../index';

declare var Object: any;
export interface VisitLogInterface {
  "visitorPresent": boolean;
  "visitId": any;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  visit?: Visit;
}

export class VisitLog implements VisitLogInterface {
  "visitorPresent": boolean;
  "visitId": any;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  visit: Visit;
  constructor(data?: VisitLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VisitLog`.
   */
  public static getModelName() {
    return "VisitLog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VisitLog for dynamic purposes.
  **/
  public static factory(data: VisitLogInterface): VisitLog{
    return new VisitLog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VisitLog',
      plural: 'VisitLogs',
      path: 'VisitLogs',
      idName: 'id',
      properties: {
        "visitorPresent": {
          name: 'visitorPresent',
          type: 'boolean'
        },
        "visitId": {
          name: 'visitId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
      },
      relations: {
        visit: {
          name: 'visit',
          type: 'Visit',
          model: 'Visit',
          relationType: 'belongsTo',
                  keyFrom: 'visitId',
          keyTo: 'id'
        },
      }
    }
  }
}
