/* tslint:disable */
import {
  UserModel
} from '../index';

declare var Object: any;
export interface CompetencyInterface {
  "name": string;
  "description"?: string;
  "hidden"?: boolean;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  users?: UserModel[];
  userCompetencies?: any[];
  userCompentencyHistory?: any[];
}

export class Competency implements CompetencyInterface {
  "name": string;
  "description": string;
  "hidden": boolean;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  users: UserModel[];
  userCompetencies: any[];
  userCompentencyHistory: any[];
  constructor(data?: CompetencyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Competency`.
   */
  public static getModelName() {
    return "Competency";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Competency for dynamic purposes.
  **/
  public static factory(data: CompetencyInterface): Competency{
    return new Competency(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Competency',
      plural: 'Competencies',
      path: 'Competencies',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "hidden": {
          name: 'hidden',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
      },
      relations: {
        users: {
          name: 'users',
          type: 'UserModel[]',
          model: 'UserModel',
          relationType: 'hasMany',
          modelThrough: 'UserCompetency',
          keyThrough: 'userId',
          keyFrom: 'id',
          keyTo: 'competencyId'
        },
        userCompetencies: {
          name: 'userCompetencies',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'competencyId'
        },
        userCompentencyHistory: {
          name: 'userCompentencyHistory',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'competencyId'
        },
      }
    }
  }
}
