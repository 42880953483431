import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../_core/_services';
import { UserModelApi } from '../../_sdk/services/custom';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;
  forgotPasswordFormErrors: any;

  isLoading: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: LoginService,
    private _auth: UserModelApi,
    private _router: Router,
    private notificationService: NotificationService
    ) {
      // Set the defaults
      this.forgotPasswordFormErrors = {
        email   : {}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    // Check the user is logged in already
    if (this._auth.isAuthenticated()) {
      this._router.navigate(['admin']);
    }

    // Set defaults
    this.isLoading = false;

    // Initialize form
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.forgotPasswordForm.valueChanges
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(() => {
      this.onLoginFormValuesChanged();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * On form values changed
   */
  onLoginFormValuesChanged(): void
  {
    for ( const field in this.forgotPasswordFormErrors )
    {
      if ( !this.forgotPasswordFormErrors.hasOwnProperty(field) )
      {
        continue;
      }

      // Clear previous errors
      this.forgotPasswordFormErrors[field] = {};

      // Get the control
      const control = this.forgotPasswordForm.get(field);

      if ( control && control.dirty && !control.valid )
      {
        this.forgotPasswordFormErrors[field] = control.errors;
      }
    }
  }

  back(): void {
    this._router.navigate(['login']);
  }

  submit(): void {
    this.isLoading = true;

    const data = this.forgotPasswordForm.getRawValue();
    this._authService.forgotPassword(data).subscribe(
      () => {
        this.notificationService.showSuccessMessage('Er is een email gestuurd naar dit emailadres met een link om een nieuw wachtwoord aan te maken.');
        this._router.navigate(['login']);
        this.isLoading = false;
      },
      () => {
        this.notificationService.showSuccessMessage('Er is een email gestuurd naar dit emailadres met een link om een nieuw wachtwoord aan te maken.');
        this._router.navigate(['login']);
        this.isLoading = false;
      }
    );
  }
}
