/* tslint:disable */
import {
  PTW,
  Control,
  HazardTemplate
} from '../index';

declare var Object: any;
export interface HazardInterface {
  "description": string;
  "order": number;
  "ptwId": any;
  "hazardTemplateId"?: any;
  "id"?: any;
  ptw?: PTW;
  controls?: Control[];
  hazardTemplate?: HazardTemplate;
}

export class Hazard implements HazardInterface {
  "description": string;
  "order": number;
  "ptwId": any;
  "hazardTemplateId": any;
  "id": any;
  ptw: PTW;
  controls: Control[];
  hazardTemplate: HazardTemplate;
  constructor(data?: HazardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Hazard`.
   */
  public static getModelName() {
    return "Hazard";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Hazard for dynamic purposes.
  **/
  public static factory(data: HazardInterface): Hazard{
    return new Hazard(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Hazard',
      plural: 'Hazards',
      path: 'Hazards',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "ptwId": {
          name: 'ptwId',
          type: 'any'
        },
        "hazardTemplateId": {
          name: 'hazardTemplateId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        ptw: {
          name: 'ptw',
          type: 'PTW',
          model: 'PTW',
          relationType: 'belongsTo',
                  keyFrom: 'ptwId',
          keyTo: 'id'
        },
        controls: {
          name: 'controls',
          type: 'Control[]',
          model: 'Control',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'hazardId'
        },
        hazardTemplate: {
          name: 'hazardTemplate',
          type: 'HazardTemplate',
          model: 'HazardTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'hazardTemplateId',
          keyTo: 'id'
        },
      }
    }
  }
}
