import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

export interface DateRange {
  from: NgbDate | null,
  to: NgbDate | null
}

@Component({
  selector: 'ngbd-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class NgbdDatepickerRange {

  @Input() default: DateRange | null;
  @Input() disabled: boolean;
  @Output() onChange = new EventEmitter<DateRange>();

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  hoveredDate: NgbDate | null = null;

  constructor(
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter) {

    // Set defaults
    if (this.default && this.default.from) {
      this.fromDate = this.default.from;
    }
    else {
      this.fromDate = calendar.getToday();
    }
    if (this.default && this.default.to) {
      this.toDate = this.default.to;
    }
    else {
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);      
    }
  }

  ngAfterViewInit() {
    this.emitChange();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.emitChange();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  emitChange(): void {
    this.onChange.emit({ from: this.fromDate, to: this.toDate });
  }
}