import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../login.service';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../_core/_services';
import { PasswordValidation } from '../../password-validation';

@Component({
  selector: 'change-password-form',
  templateUrl: './form.html'
})
// tslint:disable-next-line:component-class-suffix
export class ChangePasswordFormComponent implements OnInit, OnDestroy {

  @Input() email: string;
  changePasswordForm: FormGroup;
  changePasswordFormErrors: any;

  token: string;

  isLoading: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: LoginService,
    private _router: Router,
    private _notificationService: NotificationService,
    private _modalService: NgbModal,

  ) {
    // Set the defaults
    this.changePasswordFormErrors = {
      email: {},
      oldPassword: {},
      newPassword: {},
      confirmNewPassword: {}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    // Set defaults
    this.isLoading = false;

    // Initialize form
    this.changePasswordForm = this._formBuilder.group({
      email: [{ value: this.email, disabled: this.email }, [Validators.required, Validators.email]],
      oldPassword: ['', Validators.required],
      newPasswords: this._formBuilder.group(
        {
          newPassword: ['', this._authService.getPasswordValidators()],
          confirmNewPassword: ['', this._authService.getPasswordValidators()]
        },
        {
          validators: PasswordValidation.MatchPassword
        }
      )
    });

    this.changePasswordForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.onLoginFormValuesChanged();
      });
  }

  /**
   * On form values changed
   */
  onLoginFormValuesChanged(): void {
    for (const field in this.changePasswordFormErrors) {
      if (!this.changePasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.changePasswordFormErrors[field] = {};

      // Get the control
      let control = this.changePasswordForm.get(field);

      if (!control) {
        control = this.changePasswordForm.get('newPasswords').get(field);
      }

      if (control && control.dirty && !control.valid) {
        this.changePasswordFormErrors[field] = control.errors;
      }
    }

    // Manually update the match password validation status
    if (this.changePasswordFormErrors['confirmNewPassword'].MatchPassword &&
      this.changePasswordForm.get('newPasswords').value.newPassword === this.changePasswordForm.get('newPasswords').value.confirmNewPassword) {
      delete this.changePasswordFormErrors['confirmNewPassword'].MatchPassword;
      this.changePasswordForm.get('newPasswords').setErrors(null);
      this.changePasswordForm.get('newPasswords').get('confirmNewPassword').setErrors(null);
    }
  }

  isOpenModal(): boolean {
    return this._modalService.hasOpenModals();
  }

  closeModal(reason: string): void {
    return this._modalService.dismissAll(reason);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  submit(): void {
    this.isLoading = true;

    const email = this.changePasswordForm.get('email').value;
    const oldPassword = this.changePasswordForm.get('oldPassword').value;
    const newPassword = this.changePasswordForm.get('newPasswords').get('newPassword').value;

    this._authService.changePassword(email, oldPassword, newPassword).subscribe(
      () => {
        this._notificationService.showSuccessMessage('Het wachtwoord is succesvol gewijzigd');
        this._router.navigate(['login']);
        this.isLoading = false;
      },
      error => {
        // Find out what is wrong and show the appropriate error message
        switch (error.code) {
          /**
           * Locked for certain period
           */
          case 'ACCOUNT_LOCKED':
            // Show message
            this._notificationService.showErrorMessage('Uw account is vergrendeld vanwege herhaalde mislukte aanmeldingspogingen.');
            break;
          /**
           * Disabled by admin
           */
          case 'ACCOUNT_DISABLED':
            // Show message
            this._notificationService.showErrorMessage('Uw account is uitgeschakeld.');
            break;
          /**
           * No permissions assigned
           */
          case 'NO_PERMISSIONS':
            // Show message
            this._notificationService.showErrorMessage('Uw account heeft nog geen toestemming.');
            break;
          /**
           * No permissions assigned
           */
          case 'PASSWORD_INVALID':
            // Show message
            this._notificationService.showErrorMessage('Dit wachtwoord is al eens gebruikt voor uw account.');
            break;
          /**
           * Any other error
           */
          default:
            // Show message
            this._notificationService.showErrorMessage('Uw gebruikersnaam of wachtwoord is onjuist!');
            break;
        }
        this.isLoading = false;
      }
    );
  }
}
