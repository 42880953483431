//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPermissionsModule } from "ngx-permissions";

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import {
  NotificationModalComponent,
  NgbdSortableHeader,
  NgbdDatepickerRange,
  DisableControlDirective,
} from "./_components";
import { IconsModule } from "../icons/icons.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ChangePasswordComponent } from "../login/change-password/change-password.component";
import { ChangePasswordFormComponent } from "../login/change-password/form/form";
import { ChangePasswordModalComponent } from "../login/change-password/modal/modal";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    IconsModule,
    FontAwesomeModule,
  ],
  declarations: [
    NotificationModalComponent,
    NgbdSortableHeader,
    NgbdDatepickerRange,
    DisableControlDirective,
    ChangePasswordComponent,
    ChangePasswordFormComponent,
    ChangePasswordModalComponent,
  ],
  exports: [
    NotificationModalComponent,
    NgbdDatepickerRange,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbdSortableHeader,
    DisableControlDirective,
    NgxPermissionsModule,
    IconsModule,
    ChangePasswordComponent,
    ChangePasswordFormComponent,
    ChangePasswordModalComponent,
  ],
  entryComponents: [NotificationModalComponent],
})
export class SharedModule {}
