/* tslint:disable */
import {
  Visit,
  PTW
} from '../index';

declare var Object: any;
export interface WorkInterface {
  "description"?: string;
  "environmentDescription"?: string;
  "status"?: string;
  "visitId"?: any;
  "traCompleted"?: boolean;
  "ptwRequired"?: boolean;
  "traChecks"?: any;
  "ambientTemp"?: number;
  "oxygenConcentration"?: number;
  "carbonMonoxideContentration"?: number;
  "explosiveGasConcentration"?: number;
  "hydrogenSulfideConcentration"?: number;
  "lmraQuestions"?: any;
  "careTakerName"?: string;
  "careTakerManner"?: string;
  "postCompletionHygieneRelease": boolean;
  "postCompletionRelease"?: string;
  "postCompletionClean": boolean;
  "postCompletionChecks": boolean;
  "postCompletionLoto": boolean;
  "lmraFindings"?: string;
  "completionSignOffFromOriginator": boolean;
  "completionSignOffFromResponsiblePerson": boolean;
  "completionSignOffExecutor": boolean;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  visit?: Visit;
  ptw?: PTW;
}

export class Work implements WorkInterface {
  "description": string;
  "environmentDescription": string;
  "status": string;
  "visitId": any;
  "traCompleted": boolean;
  "ptwRequired": boolean;
  "traChecks": any;
  "ambientTemp": number;
  "oxygenConcentration": number;
  "carbonMonoxideContentration": number;
  "explosiveGasConcentration": number;
  "hydrogenSulfideConcentration": number;
  "lmraQuestions": any;
  "careTakerName": string;
  "careTakerManner": string;
  "postCompletionHygieneRelease": boolean;
  "postCompletionRelease": string;
  "postCompletionClean": boolean;
  "postCompletionChecks": boolean;
  "postCompletionLoto": boolean;
  "lmraFindings": string;
  "completionSignOffFromOriginator": boolean;
  "completionSignOffFromResponsiblePerson": boolean;
  "completionSignOffExecutor": boolean;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  visit: Visit;
  ptw: PTW;
  constructor(data?: WorkInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Work`.
   */
  public static getModelName() {
    return "Work";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Work for dynamic purposes.
  **/
  public static factory(data: WorkInterface): Work{
    return new Work(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Work',
      plural: 'Works',
      path: 'Works',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "environmentDescription": {
          name: 'environmentDescription',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "visitId": {
          name: 'visitId',
          type: 'any'
        },
        "traCompleted": {
          name: 'traCompleted',
          type: 'boolean',
          default: false
        },
        "ptwRequired": {
          name: 'ptwRequired',
          type: 'boolean',
          default: false
        },
        "traChecks": {
          name: 'traChecks',
          type: 'any'
        },
        "ambientTemp": {
          name: 'ambientTemp',
          type: 'number'
        },
        "oxygenConcentration": {
          name: 'oxygenConcentration',
          type: 'number'
        },
        "carbonMonoxideContentration": {
          name: 'carbonMonoxideContentration',
          type: 'number'
        },
        "explosiveGasConcentration": {
          name: 'explosiveGasConcentration',
          type: 'number'
        },
        "hydrogenSulfideConcentration": {
          name: 'hydrogenSulfideConcentration',
          type: 'number'
        },
        "lmraQuestions": {
          name: 'lmraQuestions',
          type: 'any'
        },
        "careTakerName": {
          name: 'careTakerName',
          type: 'string'
        },
        "careTakerManner": {
          name: 'careTakerManner',
          type: 'string'
        },
        "postCompletionHygieneRelease": {
          name: 'postCompletionHygieneRelease',
          type: 'boolean',
          default: false
        },
        "postCompletionRelease": {
          name: 'postCompletionRelease',
          type: 'string'
        },
        "postCompletionClean": {
          name: 'postCompletionClean',
          type: 'boolean',
          default: false
        },
        "postCompletionChecks": {
          name: 'postCompletionChecks',
          type: 'boolean',
          default: false
        },
        "postCompletionLoto": {
          name: 'postCompletionLoto',
          type: 'boolean',
          default: false
        },
        "lmraFindings": {
          name: 'lmraFindings',
          type: 'string'
        },
        "completionSignOffFromOriginator": {
          name: 'completionSignOffFromOriginator',
          type: 'boolean',
          default: false
        },
        "completionSignOffFromResponsiblePerson": {
          name: 'completionSignOffFromResponsiblePerson',
          type: 'boolean',
          default: false
        },
        "completionSignOffExecutor": {
          name: 'completionSignOffExecutor',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
      },
      relations: {
        visit: {
          name: 'visit',
          type: 'Visit',
          model: 'Visit',
          relationType: 'belongsTo',
                  keyFrom: 'visitId',
          keyTo: 'id'
        },
        ptw: {
          name: 'ptw',
          type: 'PTW',
          model: 'PTW',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'workId'
        },
      }
    }
  }
}
