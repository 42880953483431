import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './modal.html'
})
export class ChangePasswordModalComponent {

  email: string;

  constructor(public activeModal: NgbActiveModal) {}
}
