/* tslint:disable */
import {
  UserModel,
  Hazard,
  ControlTemplate
} from '../index';

declare var Object: any;
export interface ControlInterface {
  "description": string;
  "order": number;
  "responsiblePersonId": any;
  "hazardId": any;
  "controlTemplateId"?: any;
  "id"?: any;
  responsiblePerson?: UserModel;
  hazard?: Hazard;
  controlTemplate?: ControlTemplate;
}

export class Control implements ControlInterface {
  "description": string;
  "order": number;
  "responsiblePersonId": any;
  "hazardId": any;
  "controlTemplateId": any;
  "id": any;
  responsiblePerson: UserModel;
  hazard: Hazard;
  controlTemplate: ControlTemplate;
  constructor(data?: ControlInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Control`.
   */
  public static getModelName() {
    return "Control";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Control for dynamic purposes.
  **/
  public static factory(data: ControlInterface): Control{
    return new Control(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Control',
      plural: 'Controls',
      path: 'Controls',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "responsiblePersonId": {
          name: 'responsiblePersonId',
          type: 'any'
        },
        "hazardId": {
          name: 'hazardId',
          type: 'any'
        },
        "controlTemplateId": {
          name: 'controlTemplateId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        responsiblePerson: {
          name: 'responsiblePerson',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
                  keyFrom: 'responsiblePersonId',
          keyTo: 'id'
        },
        hazard: {
          name: 'hazard',
          type: 'Hazard',
          model: 'Hazard',
          relationType: 'belongsTo',
                  keyFrom: 'hazardId',
          keyTo: 'id'
        },
        controlTemplate: {
          name: 'controlTemplate',
          type: 'ControlTemplate',
          model: 'ControlTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'controlTemplateId',
          keyTo: 'id'
        },
      }
    }
  }
}
