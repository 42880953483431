import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { SortDirection } from '../../_interfaces/sort-direction.interface';
import { SortEvent } from '../../_interfaces/sort-event.interface';

const rotate: {[key: string]: SortDirection} =
{
  'asc': 'desc',
  'desc': '',
  '': 'asc'
};


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'th[sortable]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class.asc]' : 'direction === "asc"',
    '[class.desc]' : 'direction === "desc"',
    '(click)' : 'rotate()'
  }
})
// tslint:disable-next-line:directive-class-suffix
export class NgbdSortableHeader {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}
