import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LoginService } from "./login/login.service";
import { SDKBrowserModule } from "./_sdk";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxPermissionsModule } from "ngx-permissions";
import { SharedModule } from "./_shared/shared.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    SDKBrowserModule.forRoot(),
    SharedModule,
  ],
  providers: [
    LoginService,
    {
      provide: LOCALE_ID,
      useValue: "nl-NL", // replace "en-US" with your locale
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
