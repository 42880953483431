/* tslint:disable */
import { Injectable } from '@angular/core';
import { UserModel } from '../../models/UserModel';
import { Work } from '../../models/Work';
import { Contractor } from '../../models/Contractor';
import { Hazard } from '../../models/Hazard';
import { Visit } from '../../models/Visit';
import { VisitLog } from '../../models/VisitLog';
import { PTW } from '../../models/PTW';
import { Control } from '../../models/Control';
import { HazardTemplate } from '../../models/HazardTemplate';
import { ControlTemplate } from '../../models/ControlTemplate';
import { Competency } from '../../models/Competency';
import { OfficeVisitor } from '../../models/OfficeVisitor';
import { VisitorCompany } from '../../models/VisitorCompany';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    UserModel: UserModel,
    Work: Work,
    Contractor: Contractor,
    Hazard: Hazard,
    Visit: Visit,
    VisitLog: VisitLog,
    PTW: PTW,
    Control: Control,
    HazardTemplate: HazardTemplate,
    ControlTemplate: ControlTemplate,
    Competency: Competency,
    OfficeVisitor: OfficeVisitor,
    VisitorCompany: VisitorCompany,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
