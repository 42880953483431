import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoginService } from '../login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PasswordValidation } from '../password-validation';
import { NotificationService } from '../../_core/_services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: FormGroup;
  resetPasswordFormErrors: any;
  token: string;

  isLoading: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: LoginService,
    private _router: Router,
    private _route: ActivatedRoute,
    private notificationService: NotificationService
  )
  {
      // Set the defaults
      this.resetPasswordFormErrors = {
        newPassword        : {},
        confirmNewPassword : {}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    // Check token
    this.token = this._route.snapshot.paramMap.get('token');

    // Set defaults
    this.isLoading = false;

    // Initialize form
    this.resetPasswordForm = this._formBuilder.group(
      {
        newPassword: ['', this._authService.getPasswordValidators()],
        confirmNewPassword: ['', this._authService.getPasswordValidators()]
      },
      {
        validators: PasswordValidation.MatchPassword
      }
    );

    this.resetPasswordForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.onLoginFormValuesChanged();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * On form values changed
   */
  onLoginFormValuesChanged(): void
  {
    for ( const field in this.resetPasswordFormErrors )
    {
      if ( !this.resetPasswordFormErrors.hasOwnProperty(field) )
      {
        continue;
      }

      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};

      // Get the control
      const control = this.resetPasswordForm.get(field);

      if ( control && control.dirty && !control.valid )
      {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }


  submit(): void {
    this.isLoading = true;

    const password = this.resetPasswordForm.get('newPassword').value;

    this._authService.resetPassword(this.token, password).subscribe(
      () => {
        this.notificationService.showSuccessMessage('Het wachtwoord is succesvol gewijzigd.');
        this._router.navigate(['login']);
        this.isLoading = false;
      },
      error => {
        // Find out what is wrong and show the appropriate error message
        switch (error.code)
        {
            /**
             * Locked for certain period
             */
            case 'ACCOUNT_LOCKED':
                // Show message
                this.notificationService.showErrorMessage('Uw account is vergrendeld vanwege herhaalde mislukte aanmeldingspogingen.');
                break;
            /**
             * Disabled by admin
             */
            case 'ACCOUNT_DISABLED':
                // Show message
                this.notificationService.showErrorMessage('Uw account is uitgeschakeld.');
                break;
            /**
             * No permissions assigned
             */
            case 'NO_PERMISSIONS':
                // Show message
                this.notificationService.showErrorMessage('Uw account heeft nog geen toestemming.');
                break;
            /**
             * No permissions assigned
             */
            case 'PASSWORD_INVALID':
                // Show message
                this.notificationService.showErrorMessage('Dit wachtwoord is al eens gebruikt voor uw account.');
                break;
            /**
             * Any other error
             */
            default:
                // Show message
                this.notificationService.showErrorMessage('Uw gebruikersnaam of wachtwoord is onjuist!');
                break;
        }
        this.isLoading = false;
      }
    );
  }
}
