//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, CanLoad, Route } from '@angular/router';


//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { Observable } from 'rxjs';


//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { UserModelApi, LoopBackAuth } from '../../_sdk';


@Injectable({
  providedIn: 'root'
})
export class LoadGuard implements CanLoad {

  constructor(private _auth: UserModelApi, private _lbAuth: LoopBackAuth, private _router: Router) { }

  canLoad(route: Route): boolean {
    return true;
  }
}
