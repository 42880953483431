/* tslint:disable */

declare var Object: any;
export interface OfficeVisitorInterface {
  "date": Date;
  "name": string;
  "email"?: string;
  "checkInCode": string;
  "phoneNumber"?: string;
  "status"?: string;
  "companyName": string;
  "userId": any;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  "visitorCompanyId"?: any;
  officeVisitorUser?: any;
}

export class OfficeVisitor implements OfficeVisitorInterface {
  "date": Date;
  "name": string;
  "email": string;
  "checkInCode": string;
  "phoneNumber": string;
  "status": string;
  "companyName": string;
  "userId": any;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  "visitorCompanyId": any;
  officeVisitorUser: any;
  constructor(data?: OfficeVisitorInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OfficeVisitor`.
   */
  public static getModelName() {
    return "OfficeVisitor";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OfficeVisitor for dynamic purposes.
  **/
  public static factory(data: OfficeVisitorInterface): OfficeVisitor{
    return new OfficeVisitor(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OfficeVisitor',
      plural: 'OfficeVisitors',
      path: 'OfficeVisitors',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "checkInCode": {
          name: 'checkInCode',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "companyName": {
          name: 'companyName',
          type: 'string'
        },
        "userId": {
          name: 'userId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
        "visitorCompanyId": {
          name: 'visitorCompanyId',
          type: 'any'
        },
      },
      relations: {
        officeVisitorUser: {
          name: 'officeVisitorUser',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
