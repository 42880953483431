//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';


//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { Observable } from 'rxjs';


//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { UserModelApi, LoopBackAuth } from '../../_sdk';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private _auth: UserModelApi, private _lbAuth: LoopBackAuth, private _router: Router) { }

  isLoginRoute(state: RouterStateSnapshot): boolean {
    return state.url.indexOf('login') !== -1;
  }

  passOnLoginRoute(state: RouterStateSnapshot, observer): void {
    // prevent login state from preventing itself..
    if (this.isLoginRoute(state)) {
      // allow the route
      observer.next(true);
      observer.complete();
    } else {
      // disallow the route
      observer.next(false);
      observer.complete();

      // navigate the the login state
      this._router.navigate(['login']);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create(observer => {
      // check whether we even have a cookie/session and prevent login state from preventing itself..
      if (!this._auth.isAuthenticated()) {
        return this.passOnLoginRoute(state, observer);
      }

      // do have a cookie so check with the api if its still a valid session
      this._auth.getCurrent().subscribe(
        result => {
          // prevent login state if logged in..
          if (this.isLoginRoute(state)) {
            // navigate the the admin state
            this._router.navigate(['admin']);
          }

          // allow the route
          observer.next(true);
          observer.complete();
        },
        err => {
          // clear browser cookies
          this._lbAuth.clear();

          // prevent login state from preventing itself..
          this.passOnLoginRoute(state, observer);
        }
      );
    });
  }
}
