import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { UserModelApi } from '../_sdk/services/custom';

@Injectable()
export class LoginService {
  credentials = {
    email: '',
    password: ''
  };

  constructor(private _userApi: UserModelApi) {}

  forgotPassword(data: any): Observable<any> {
    return this._userApi.resetPassword(data);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this._userApi.setResetPassword(token , password);
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    return this._userApi.updatePassword(email, oldPassword, newPassword);
  }


  isAuthenticated(): boolean {
    return this._userApi.isAuthenticated();
  }


  getPasswordValidators(): Validators[] {
    const min = 10;
    const max = 24;
    const pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$';

    return [
      Validators.required,
      Validators.minLength(min),
      Validators.maxLength(max),
      Validators.pattern(pattern)
    ];
  }
}
