/* tslint:disable */

declare var Object: any;
export interface UserModelInterface {
  "firstName": string;
  "insertion"?: string;
  "lastName": string;
  "position"?: string;
  "contractorName"?: string;
  "phoneNumber"?: string;
  "notes"?: string;
  "dateLastLogin"?: Date;
  "dateAccountUnlocked"?: Date;
  "dateAccountDisabled"?: Date;
  "securityInstructionApproval"?: any;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  "contractorManagerId"?: any;
  "contractorUserId"?: any;
  "password"?: string;
  passwordHistory?: any[];
  accessTokens?: any[];
  roles?: any[];
  contractorManager?: any;
  contractorUser?: any;
  visitsAsVisitor?: any[];
  visitsAsPersonInCharge?: any[];
  responsiblePersonForControl?: any[];
  competencies?: any[];
  userCompetencies?: any[];
  competencyHistory?: any[];
  officeVisitor?: any;
  roleMappings?: any[];
}

export class UserModel implements UserModelInterface {
  "firstName": string;
  "insertion": string;
  "lastName": string;
  "position": string;
  "contractorName": string;
  "phoneNumber": string;
  "notes": string;
  "dateLastLogin": Date;
  "dateAccountUnlocked": Date;
  "dateAccountDisabled": Date;
  "securityInstructionApproval": any;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  "contractorManagerId": any;
  "contractorUserId": any;
  "password": string;
  passwordHistory: any[];
  accessTokens: any[];
  roles: any[];
  contractorManager: any;
  contractorUser: any;
  visitsAsVisitor: any[];
  visitsAsPersonInCharge: any[];
  responsiblePersonForControl: any[];
  competencies: any[];
  userCompetencies: any[];
  competencyHistory: any[];
  officeVisitor: any;
  roleMappings: any[];
  constructor(data?: UserModelInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserModel`.
   */
  public static getModelName() {
    return "UserModel";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserModel for dynamic purposes.
  **/
  public static factory(data: UserModelInterface): UserModel{
    return new UserModel(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserModel',
      plural: 'UserModels',
      path: 'UserModels',
      idName: 'id',
      properties: {
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "insertion": {
          name: 'insertion',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'string'
        },
        "contractorName": {
          name: 'contractorName',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "dateLastLogin": {
          name: 'dateLastLogin',
          type: 'Date'
        },
        "dateAccountUnlocked": {
          name: 'dateAccountUnlocked',
          type: 'Date'
        },
        "dateAccountDisabled": {
          name: 'dateAccountDisabled',
          type: 'Date'
        },
        "securityInstructionApproval": {
          name: 'securityInstructionApproval',
          type: 'any'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
        "contractorManagerId": {
          name: 'contractorManagerId',
          type: 'any'
        },
        "contractorUserId": {
          name: 'contractorUserId',
          type: 'any'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        passwordHistory: {
          name: 'passwordHistory',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        roles: {
          name: 'roles',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'RoleMapping',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'principalId'
        },
        contractorManager: {
          name: 'contractorManager',
          type: 'any',
          model: '',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'contractorManagerId'
        },
        contractorUser: {
          name: 'contractorUser',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'contractorUserId',
          keyTo: 'id'
        },
        visitsAsVisitor: {
          name: 'visitsAsVisitor',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'visitorUserId'
        },
        visitsAsPersonInCharge: {
          name: 'visitsAsPersonInCharge',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'personInChargeUserId'
        },
        responsiblePersonForControl: {
          name: 'responsiblePersonForControl',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'responsiblePersonId'
        },
        competencies: {
          name: 'competencies',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'UserCompetency',
          keyThrough: 'competencyId',
          keyFrom: 'id',
          keyTo: 'userId'
        },
        userCompetencies: {
          name: 'userCompetencies',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        competencyHistory: {
          name: 'competencyHistory',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        officeVisitor: {
          name: 'officeVisitor',
          type: 'any',
          model: '',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        roleMappings: {
          name: 'roleMappings',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'principalId'
        },
      }
    }
  }
}
