import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { LoginGuard } from "./_core";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./login/change-password/change-password.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "reset-password/:token",
        component: ResetPasswordComponent,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
      },
      {
        path: "admin",
        loadChildren: "./admin/admin.module#AdminModule",
        canActivate: [LoginGuard],
      },
      {
        path: "**",
        redirectTo: "login",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
