/* tslint:disable */
import {
  UserModel,
  Contractor,
  Work,
  VisitLog
} from '../index';

declare var Object: any;
export interface VisitInterface {
  "date": Date;
  "endDate": Date;
  "endStopDate"?: Date;
  "kind"?: string;
  "status"?: string;
  "visitorPresent"?: boolean;
  "visitorUserId"?: any;
  "contractorId"?: any;
  "personInChargeUserId"?: any;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  visitor?: UserModel;
  contractor?: Contractor;
  personInCharge?: UserModel;
  works?: Work[];
  visitLogs?: VisitLog[];
}

export class Visit implements VisitInterface {
  "date": Date;
  "endDate": Date;
  "endStopDate": Date;
  "kind": string;
  "status": string;
  "visitorPresent": boolean;
  "visitorUserId": any;
  "contractorId": any;
  "personInChargeUserId": any;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  visitor: UserModel;
  contractor: Contractor;
  personInCharge: UserModel;
  works: Work[];
  visitLogs: VisitLog[];
  constructor(data?: VisitInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Visit`.
   */
  public static getModelName() {
    return "Visit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Visit for dynamic purposes.
  **/
  public static factory(data: VisitInterface): Visit{
    return new Visit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Visit',
      plural: 'Visits',
      path: 'Visits',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "endStopDate": {
          name: 'endStopDate',
          type: 'Date'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "visitorPresent": {
          name: 'visitorPresent',
          type: 'boolean',
          default: false
        },
        "visitorUserId": {
          name: 'visitorUserId',
          type: 'any'
        },
        "contractorId": {
          name: 'contractorId',
          type: 'any'
        },
        "personInChargeUserId": {
          name: 'personInChargeUserId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
      },
      relations: {
        visitor: {
          name: 'visitor',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
                  keyFrom: 'visitorUserId',
          keyTo: 'id'
        },
        contractor: {
          name: 'contractor',
          type: 'Contractor',
          model: 'Contractor',
          relationType: 'belongsTo',
                  keyFrom: 'contractorId',
          keyTo: 'id'
        },
        personInCharge: {
          name: 'personInCharge',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
                  keyFrom: 'personInChargeUserId',
          keyTo: 'id'
        },
        works: {
          name: 'works',
          type: 'Work[]',
          model: 'Work',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'visitId'
        },
        visitLogs: {
          name: 'visitLogs',
          type: 'VisitLog[]',
          model: 'VisitLog',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'visitId'
        },
      }
    }
  }
}
