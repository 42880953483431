export const permissions = {
  roles: [
    {
      value: "admin",
      viewValue: "Admin",
      role: "ADMIN",
    },
    {
      value: "supervisor",
      viewValue: "Klusverantwoordelijke",
      role: "SUPERVISOR",
    },
    {
      value: "contractor",
      viewValue: "Aannemer",
      role: "CONTRACTOR",
    },
    {
      value: "executor",
      viewValue: "Klusuitvoerder",
      role: "EXECUTOR",
    },
    {
      value: "gatekeeper",
      viewValue: "Portier",
      role: "GATEKEEPER",
    },
    {
      value: "wkc",
      viewValue: "WKC",
      role: "WKC",
    },
    {
      value: "checkin",
      viewValue: "Check-in",
      role: "CHECKIN",
    },
    {
      value: "employee",
      viewValue: "Werknemer DH",
      role: "EMPLOYEE",
    },
  ],
};
