/* tslint:disable */
import {
  Work,
  Hazard
} from '../index';

declare var Object: any;
export interface PTWInterface {
  "workId": any;
  "orderNumber"?: string;
  "location"?: string;
  "validStartDate": Date;
  "validEndDate": Date;
  "emergencyPhoneNumber"?: string;
  "signOffFromOriginator": boolean;
  "signOffFromResponsiblePerson": boolean;
  "signOffExecutor": boolean;
  "additionalControls": any;
  "id"?: any;
  "dateCreated"?: Date;
  "dateModified"?: Date;
  work?: Work;
  hazards?: Hazard[];
}

export class PTW implements PTWInterface {
  "workId": any;
  "orderNumber": string;
  "location": string;
  "validStartDate": Date;
  "validEndDate": Date;
  "emergencyPhoneNumber": string;
  "signOffFromOriginator": boolean;
  "signOffFromResponsiblePerson": boolean;
  "signOffExecutor": boolean;
  "additionalControls": any;
  "id": any;
  "dateCreated": Date;
  "dateModified": Date;
  work: Work;
  hazards: Hazard[];
  constructor(data?: PTWInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PTW`.
   */
  public static getModelName() {
    return "PTW";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PTW for dynamic purposes.
  **/
  public static factory(data: PTWInterface): PTW{
    return new PTW(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PTW',
      plural: 'PTWs',
      path: 'PTWs',
      idName: 'id',
      properties: {
        "workId": {
          name: 'workId',
          type: 'any'
        },
        "orderNumber": {
          name: 'orderNumber',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "validStartDate": {
          name: 'validStartDate',
          type: 'Date'
        },
        "validEndDate": {
          name: 'validEndDate',
          type: 'Date'
        },
        "emergencyPhoneNumber": {
          name: 'emergencyPhoneNumber',
          type: 'string'
        },
        "signOffFromOriginator": {
          name: 'signOffFromOriginator',
          type: 'boolean',
          default: false
        },
        "signOffFromResponsiblePerson": {
          name: 'signOffFromResponsiblePerson',
          type: 'boolean',
          default: false
        },
        "signOffExecutor": {
          name: 'signOffExecutor',
          type: 'boolean',
          default: false
        },
        "additionalControls": {
          name: 'additionalControls',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateModified": {
          name: 'dateModified',
          type: 'Date'
        },
      },
      relations: {
        work: {
          name: 'work',
          type: 'Work',
          model: 'Work',
          relationType: 'belongsTo',
                  keyFrom: 'workId',
          keyTo: 'id'
        },
        hazards: {
          name: 'hazards',
          type: 'Hazard[]',
          model: 'Hazard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'ptwId'
        },
      }
    }
  }
}
